import React, { Component } from 'react';

class EncounterGenerator extends Component {
	render() {
		return (
			<div className="encountergenerator">
				EncounterGenerator
			</div>
		);
	}
}

export default EncounterGenerator;
